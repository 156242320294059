//require('./preventDocumentScroll');
let showLinkType = require('./showLinkType');
let load2x = require('./load2x');
let trackSticky = require('client/trackSticky');
let lang = require('config').lang;
let {shopCurrency, localCurrency} = require('config');
const getSymbolFromCurrency = require('currency-symbol-map');
//require('newsletter/client').init();

function init() {
  showLinkType();

  if (window.devicePixelRatio > 1) {
    load2x();
  }

  window.addEventListener('scroll', trackSticky, {passive: true});
  window.addEventListener('resize', trackSticky, {passive: true});
  trackSticky();

  /*
  let prices = document.querySelectorAll('.auto-currency');
  for (let i = 0; i < prices.length; i++) {
    let priceElem = prices[i];

    if (lang === 'ru') {
      let price = Math.round(parseInt(priceElem.innerHTML) / window.rateShopTo.USD);
      priceElem.insertAdjacentHTML('beforeEnd', `<span class="auto-currency__usd">(≈$${price})</span>`);
    } else if (localCurrency !== shopCurrency) {
      let price = Math.round(window.rateShopTo[window.localCurrency] / parseInt(priceElem.innerHTML));
      priceElem.insertAdjacentHTML('beforeEnd', `<span class="auto-currency__usd">(≈${price}${getSymbolFromCurrency(currency)})</span>`);
    }
  }
  */
}

init();

//exports.trackSticky = trackSticky;
