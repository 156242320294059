let hoverIntent = require('client/hoverIntent');

module.exports = function() {

  let tooltipSpan = null;
  let shiftX = 8;
  let shiftY = 10;

  function updatePosition(event) {
    let left = event.clientX + shiftX;
    if (left + tooltipSpan.offsetWidth > document.documentElement.clientWidth) { // if beyond the right document border
      // mirror to the left
      left = Math.max(0, event.clientX - shiftX - tooltipSpan.offsetWidth);
    }
    tooltipSpan.style.left = left + 'px';

    let top = event.clientY + shiftY;
    if (top + tooltipSpan.offsetHeight > document.documentElement.clientHeight) {
      top = Math.max(0, event.clientY - shiftY - tooltipSpan.offsetHeight);
    }

    tooltipSpan.style.top = top + 'px';
  }



  // we show tooltip element for any link hover, but few of them actually get styled
  function onOver(target) {
    // if (event.target.className == 'course-top') debugger
    // console.log("tooltip over", target);
    if (!target.closest) return; // over svg

    // links inside toolbars need no tooltips
    if (target.tagName == 'A' && target.closest('.toolbar')) return;

    // <a> styled as buttons need no tooltips
    if (target.classList.contains('button')) return;

    // console.log("tooltip over show");

    tooltipSpan = document.createElement('span');
    tooltipSpan.className = 'link__type';

    if (target.getAttribute('data-tooltip')) {
      tooltipSpan.innerHTML = target.getAttribute('data-tooltip');
      tooltipSpan.setAttribute('data-tooltip', '1');
    } else {
      tooltipSpan.setAttribute('data-url', target.getAttribute('href'));
    }

    document.body.append(tooltipSpan);
    updatePosition(event);

    document.addEventListener('mousemove', updatePosition);
  }

  function onOut() {
    // console.log("tooltip out", tooltipSpan);
    if (!tooltipSpan) return;

    // console.log("tooltip out hide");

    document.removeEventListener('mousemove', updatePosition);
    tooltipSpan.remove();
    tooltipSpan = null;
  }

  hoverIntent('a,[data-tooltip]', onOver, onOut);

};
