
let lastPageX = Infinity, lastPageY = Infinity, lastTime = Date.now();

let elementOver;

let elementHoverOver;

let speedTolerance = 0.2;

let handlers = {};

function hoverIntent(selector, over, out) {
  handlers[selector] = {over: over, out: out};
}


document.addEventListener('mousemove', mousemove);
document.addEventListener('mouseout', mouseout);

function mousemove(event) {
  if (elementHoverOver) return;

  let distance = Math.sqrt(Math.pow(event.pageX - lastPageX, 2) + Math.pow(event.pageY - lastPageY, 2));
  let speed = distance / (Date.now() - lastTime);

  // slow down => call over(), get the element of interest,
  // then out() when leaving it
  if (speed < speedTolerance) {
    // console.log("speed", speed);
    let elem = document.elementFromPoint(event.clientX, event.clientY);
    if (!elem) return; // the coords are out of window (happens)
    if (elem !== elementOver) {
      for (let selector in handlers) {
        let closest = elem.closest(selector);
        if (closest) {
          // console.log("over ", closest,  handlers[selector].over);
          elementHoverOver = { elem: closest, out: handlers[selector].out};
          handlers[selector].over(closest);
        }
      }
      elementOver = elem;
    }
  }

  lastPageX = event.pageX;
  lastPageY = event.pageY;
  lastTime = Date.now();

}

function mouseout(event) {
  if (!elementHoverOver) return;

  let parent = event.relatedTarget;
  while(parent) {

    if (parent.hasAttribute('data-tooltip') && parent !== elementHoverOver.elem) {
      // nested tooltipped element, need to generate out from the enclosing tooltipped element
      break;
    }

    if (parent === elementHoverOver.elem) {
      // still under elementHoverOver
      // console.log("mouseout false", event.target, elementHoverOver.elem);
      return;
    }

    parent = parent.parentElement;
  }

  // console.log("out of parent", parent);
  let {elem, out} = elementHoverOver;
  elementHoverOver = null;
  out(elem);

}

module.exports = hoverIntent;
